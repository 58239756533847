
// Fonts

@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import "variables_bs4";

// Bootstrap 4
@import '~bootstrap/scss/bootstrap';

@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker.css';

// Font-Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";

// Our common stuff
@import "common";
