// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Brands
$theme-colors: (
    "primary": #3097D1,
    "info": #8eb4cb,
    "success": #2ab27b,
    "warning": #cbb956,
    "danger": #bf5329
);

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$text-color: #000000; // #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
// this is thowing a warning something about use of abs() and percentages
$input-border: lighten($text-color, 60%);
$input-border-focus: theme-color-level("primary", -10);
$input-color-placeholder: lighten($text-color, 50%);
